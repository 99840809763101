import Icon from '@bugbug/core/components/Icon';
import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

import Page from '~/components/Page';
import { projectItemTileStyles } from '~/components/ProjectItem/ProjectItem.styled';

export const Container = styled(Page)``;

export const ProjectItemsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 326px);
  align-items: start;
  column-gap: 40px;
  row-gap: 20px;
  overflow: hidden;
`;

export const NewProjectItem = styled.button`
  ${projectItemTileStyles};
  justify-content: center;
  align-items: center;
`;

export const NewProjectContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const NewProjectText = styled.p`
  margin-top: 7px;
  margin-bottom: 0;
  color: ${COLOR.PRIMARY_LIGHT};
  font-size: 15px;
  font-weight: ${FONT_WEIGHT.BOLD};
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 74px;
`;

export const AddIcon = styled(Icon).attrs(() => ({
  name: 'add',
}))`
  width: 32px;
  height: 32px;
  color: ${COLOR.PRIMARY_LIGHT};

  path {
    stroke-width: 1px;
  }
`;

export const Projects = styled.div`
  padding-bottom: 74px;
`;
