/* eslint-disable-next-line prettier/prettier */
import DropdownComponent, { DropdownItem as DropdownItemBase } from '@bugbug/core/components/Dropdown';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { complement, prop, path } from 'ramda';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import ScreenshotPlaceholderImage from '~/images/screenshot-placeholder.svg?react';

export const projectItemTileStyles = css`
  min-height: 250px;
  width: 100%;
  border: 2px solid ${COLOR.GRAY_1};
  padding: 0;
  transition: border-color 200ms ease;
  background-color: transparent;
  display: flex;
  flex-direction: column;

  &:hover {
    text-decoration: none;
  }

  ${styleWhenTrue(
    complement(path(['theme', 'inactive'])),
    css`
      cursor: pointer;
      &:hover {
        border-color: ${COLOR.PRIMARY_LIGHT};
      }
    `,
  )}
`;

export const Name = styled.p`
  font-size: 15px;
  padding: 0;
  margin: 0;
  flex: 1;
  text-align: left;
  color: ${COLOR.DARK_GRAY};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LinkContainer = styled(Link)`
  ${projectItemTileStyles};

  &:hover ${Name} {
    text-decoration: none;
  }
`;

export const Container = styled.div`
  ${projectItemTileStyles};
  cursor: default;
`;

export const ScreenshotWrapper = styled.div`
  width: 100%;
  height: 190px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Bar = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 17px;
  background-color: ${COLOR.WHITE};
  flex: 1;
`;

export const Actions = styled.div`
  margin-left: 12px;
  width: 30px;
  height: 30px;
`;

export const Screenshot = styled.img`
  max-width: 100%;

  ${styleWhenTrue(
    prop('inactive'),
    css`
      filter: saturate(0%);
    `,
  )}
`;

export const ScreenshotPlaceholder = styled(ScreenshotPlaceholderImage)`
  width: 100%;
  height: 100%;
`;

export const Dropdown = styled(DropdownComponent)`
  height: inherit;
  width: inherit;

  button {
    padding: 0;
  }
`;

export const DropdownItem = styled(DropdownItemBase)`
  display: flex;
  align-items: center;
  width: 160px;
`;

export const InactiveContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 16px;
  background-color: rgb(255, 255, 255, 0.73);
  transition: background-color 400ms ease;

  ${Container}:hover & {
    background-color: ${COLOR.WHITE};
  }
`;

export const InactiveBadge = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
  padding: 0 6px 2px;
  border-radius: 5px;
  background-color: #9fa0af;
  color: ${COLOR.WHITE};
  line-height: 18px;
  font-weight: ${FONT_WEIGHT.BOLD};
  z-index: 1;
`;

export const InactiveContent = styled.div`
  transition: opacity 400ms ease;
  opacity: 0;
  padding: 40px 4px 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  button > div {
    justify-content: center;
  }

  ${Container}:hover & {
    opacity: 1;
  }
`;

export const InactiveMessage = styled.p`
  margin-bottom: 18px;
`;
