import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { DROPDOWN_VARIANT } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { renderWhenTrueOtherwise, renderWhenTrue } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import {
  selectIsOrganizationAdmin,
  selectIsProjectsLimitReached,
} from '~/modules/organization/organization.selectors';
import { ProjectActions } from '~/modules/project/project.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import urls, { reverse } from '~/views/urls';

import * as S from './ProjectItem.styled';

const ProjectItem = ({ project }) => {
  const { t } = useTranslation();
  const modal = useModal();
  const history = useHistory();
  const { organizationId } = useParams();
  const isAdmin = useSelector(selectIsOrganizationAdmin);
  const isProjectsLimitReached = useSelector(selectIsProjectsLimitReached);
  const dispatch = useDispatch();
  const appRoutes = useAppRoutes('projectsList');

  const goToSubscription = useCallback(() => {
    const path = reverse(urls.subscription, { organizationId });
    history.push(path);
  }, [history, organizationId]);

  const openRenameModal = useCallback(() => {
    appRoutes.push('projectsEdit', { projectId: project.id });
  }, [appRoutes, project]);

  const openDeleteModal = useCallback(() => {
    modal.show('delete_project', { project });
  }, [modal, project]);

  const handleActionsClick = useCallback((event) => event.stopPropagation(), []);

  const trackEvent = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.OPEN_PROJECT);
  }, []);

  const handleClone = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.DUPLICATE_PROJECT);
    dispatch(ProjectActions.cloneRequest(project.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id, dispatch, isProjectsLimitReached]);

  const renderScreenshot = renderWhenTrueOtherwise(
    () => (
      <S.Screenshot
        inactive={!project.isActive || project.isDraft}
        src={project.screenshot}
        alt={t('projectItem.screenshotAlt', `{{name}} screenshot`, {
          interpolation: { escapeValue: false },
          name: project.name,
        })}
      />
    ),
    () => (
      <S.ScreenshotPlaceholder
        aria-label={t('projectItem.screenshotPlaceholderAlt', 'Screenshot placeholder')}
      />
    ),
  )(!!project.screenshot);

  const renderInactiveMessage = renderWhenTrue(() => (
    <S.InactiveContainer>
      <S.InactiveBadge>{t('projectItem.inactive.badge', 'INACTIVE')}</S.InactiveBadge>
      <S.InactiveContent>
        <S.InactiveMessage>
          {t(
            'projectItem.inactive.message',
            "You've reached the limit of projects on your current plan. To unlock this project you need to upgrade to a higher plan.",
          )}
        </S.InactiveMessage>
        {isAdmin && (
          <Button onClick={goToSubscription} variant={BUTTON_VARIANT.PRIMARY} bordered>
            {t('projectItem.inactive.button', 'Upgrade')}
          </Button>
        )}
      </S.InactiveContent>
    </S.InactiveContainer>
  ))(!project.isActive && !project.isDraft);

  const renderCloneMessage = renderWhenTrue(() => (
    <S.InactiveContainer>
      <S.InactiveBadge>{t('projectItem.clone.badge', 'DUPLICATE IN PROGRESS')}</S.InactiveBadge>
    </S.InactiveContainer>
  ))(project.isDraft);

  const projectItemUrl =
    project.isActive && !project.isDraft
      ? reverse(urls.project, {
          projectId: project.id,
          projectSlug: project.slug,
          organizationId,
        })
      : undefined;

  const ProjectContainer = projectItemUrl ? S.LinkContainer : S.Container;

  return (
    <ThemeProvider theme={{ inactive: !project.isActive || project.isDraft }}>
      <ProjectContainer
        role="button"
        data-testid="ProjectItem"
        to={projectItemUrl}
        onClick={trackEvent}
      >
        <S.ScreenshotWrapper>
          {renderScreenshot}
          {renderInactiveMessage}
          {renderCloneMessage}
        </S.ScreenshotWrapper>
        <S.Bar>
          <S.Name>{project.name}</S.Name>
          <S.Actions onClick={handleActionsClick}>
            <S.Dropdown variant={DROPDOWN_VARIANT.ICON} iconName="more" disabled={project.isDraft}>
              <S.DropdownItem disabled={!project.isActive} onClick={openRenameModal}>
                <Icon name="edit" /> {t('projectItem.editButton.label', 'Rename')}
              </S.DropdownItem>
              <S.DropdownItem disabled={!project.isActive} onClick={handleClone}>
                <Icon name="clone" /> {t('projectItem.duplicateButton.label', 'Duplicate')}
              </S.DropdownItem>
              <S.DropdownItem data-testid="ProjectItem.DeleteAction" onClick={openDeleteModal}>
                <Icon name="delete" /> {t('projectItem.deleteButton', 'Delete')}
              </S.DropdownItem>
            </S.Dropdown>
          </S.Actions>
        </S.Bar>
      </ProjectContainer>
    </ThemeProvider>
  );
};

ProjectItem.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    screenshot: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
    isDraft: PropTypes.bool,
  }).isRequired,
};

export default ProjectItem;
