import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'react-use';

import type { Route } from '@bugbug/core/types/routes';
import type { SideEffect } from '@bugbug/core/types/utils';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { selectProjectById } from '~/modules/project/project.selectors';
import analytics from '~/services/analytics';

const ProjectDetails = () => {
  const modal = useModal();
  const appRoutes = useAppRoutes('projectsList');
  const { projectId } = appRoutes.params;
  const isNew = projectId === 'new';

  const project = useSelector(selectProjectById(projectId));

  const handleHide = useCallback<SideEffect<Route>>(
    (route: Route = 'projectsList') => {
      appRoutes.push(route);
    },
    [appRoutes],
  );

  useMount(() => {
    if (!isNew) {
      analytics.trackEvent('project_opened');
    }
  });

  useEffect(() => {
    modal.show('project', { project }, { onHide: handleHide });
  }, [handleHide, modal, project]);

  return null;
};

export default ProjectDetails;
