import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgScreenshotPlaceholder = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 327, height: 191, viewBox: "0 0 327 191", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "project-placeholder_1" }),
  /* @__PURE__ */ jsx("g", { id: "Layer_2", children: /* @__PURE__ */ jsxs("g", { id: "Layer_1-2", children: [
    /* @__PURE__ */ jsx("rect", { x: 0.5, y: 0.5, width: 326, height: 190, style: {
      fill: "#fff",
      stroke: "#fff",
      strokeMiterlimit: 10
    } }),
    /* @__PURE__ */ jsx("rect", { x: 14, y: 12.5, width: 297, height: 22.5, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 14, y: 45, width: 48, height: 73, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 74, y: 45, width: 48, height: 73, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 133, y: 127, width: 94, height: 16, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 240, y: 45, width: 71, height: 122, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 16, y: 151, width: 211, height: 16, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 133.5, y: 44.61, width: 93.5, height: 5.26, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 133.5, y: 54.35, width: 93.5, height: 5.26, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 133.5, y: 64.08, width: 93.5, height: 5.26, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 133.5, y: 73.81, width: 93.5, height: 5.26, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 133.5, y: 83.54, width: 93.5, height: 5.26, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 133.5, y: 93.27, width: 93.5, height: 5.26, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 133.5, y: 103.01, width: 93.5, height: 5.26, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 133.5, y: 112.74, width: 93.5, height: 5.26, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 14.5, y: 127, width: 107.5, height: 5.27, style: {
      fill: "#ededed"
    } }),
    /* @__PURE__ */ jsx("rect", { x: 14.5, y: 136.73, width: 107.5, height: 5.27, style: {
      fill: "#ededed"
    } })
  ] }) })
] });
const ForwardRef = forwardRef(SvgScreenshotPlaceholder);
export default ForwardRef;
